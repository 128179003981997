export default [
  {
    key: 'productCategoryId',
    label: 'field.category',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'productCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'productBrandId',
    label: 'field.brand',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'productBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    type: 'text',
    operator: '%',
  },
]
