export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'productCategory',
    label: 'field.category',
  },
  {
    key: 'productBrand',
    label: 'field.brand',
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    hideToggle: true,
  },
  {
    key: 'name',
    label: 'field.name',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'balance',
    label: 'field.stockBalance',
    hideToggle: true,
  },
  // {
  //   key: 'balance',
  //   label: 'field.availableStockBalance',
  //   hideToggle: true,
  // },
  // {
  //   key: 'expiredBalance',
  //   label: 'field.expiredStockBalance',
  //   tdClass: 'text-danger',
  //   hideToggle: true,
  // },
  // {
  //   key: 'totalBalance',
  //   label: 'field.stockBalance',
  //   hideToggle: true,
  // },
];
